import React, { useState } from 'react';
import './App.css';
import logo from './assets/logo.png';
import complianceImage from './assets/Compliance.png';
import zoningImage from './assets/Zoning.png';
import verdantiaPMSImage from './assets/VerdantiaPMS.png'; // VerdantiaPMS image

function App() {
  const [showOverlay, setShowOverlay] = useState(false);
  const [overlayImage, setOverlayImage] = useState(null); // Track the clicked image

  const openOverlay = (image) => {
    setOverlayImage(image);
    setShowOverlay(true);
  };

  const closeOverlay = () => {
    setOverlayImage(null);
    setShowOverlay(false);
  };

  return (
    <div className="App">
      <nav className="navbar">
        <div className="navbar-logo">
          <img src={logo} alt="Verdantia Law Logo" className="logo-image" />
        </div>
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSd8AITO_NG0FjTOymEi4Pa0kMEOKzO5kw2aa4XjY5jyD54Mtg/viewform?embedded=true" className="cta-button navbar-button">Sign-Up to Waiting List</a>
      </nav>

      <header className="hero">
        <div className="hero-content">
          <div className="hero-text">
            <h1 className="hero-title">Seamless Compliance with Cannabis Law</h1>
            <p className="hero-subtitle">Collaborate, plan, and manage cannabis law compliance with ease – streamline everyday tasks for legal professionals.</p>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSd8AITO_NG0FjTOymEi4Pa0kMEOKzO5kw2aa4XjY5jyD54Mtg/viewform?embedded=true" className="cta-button">Sign-Up to Waiting List</a>
          </div>
        </div>
        <div className="hero-image-container">
          <div className="hero-image">
            <img
              src={complianceImage}
              alt="Compliance"
              className="content-image clickable"  // Added clickable class
              onClick={() => openOverlay(complianceImage)} // Clicking opens the image in overlay
            />
          </div>
        </div>
      </header>

      <section className="section section-light">
        <div className="content">
          <div>
            <h2>Stay ahead with real-time tracking of licensing, zoning, and jurisdictional laws</h2>
            <p>Our solution consolidates critical compliance information, automatically updating licensing requirements, zoning laws, and jurisdictional regulations.</p>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSd8AITO_NG0FjTOymEi4Pa0kMEOKzO5kw2aa4XjY5jyD54Mtg/viewform?embedded=true" className="cta-button">Sign-Up to Waiting List</a>
          </div>
          <div className="content-image-container">
            <img
              src={zoningImage}
              alt="Zoning"
              className="content-image clickable"  // Added clickable class
              onClick={() => openOverlay(zoningImage)} // Clicking opens the image in overlay
            />
          </div>
        </div>
      </section>

      {/* Not another stand-alone platform Section with VerdantiaPMS image */}
      <section className="section section-dark">
        <div className="content">
          <div className="hero-image-container"> {/* Applying the same size as the first section */}
            <div className="hero-image">
              <img
                src={verdantiaPMSImage}
                alt="Verdantia PMS"
                className="content-image clickable"  // Added clickable class
                onClick={() => openOverlay(verdantiaPMSImage)} // Clicking opens the image in overlay
              />
            </div>
          </div>
          <div>
            <h2>Not another stand-alone platform</h2>
            <p>Our platform integrates with your existing practice management system so you don't need to overhaul your current setup.</p>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSd8AITO_NG0FjTOymEi4Pa0kMEOKzO5kw2aa4XjY5jyD54Mtg/viewform?embedded=true" className="cta-button">Sign-Up to Waiting List</a>
          </div>
        </div>
      </section>

      <footer className="footer">
        <div className="footer-content">
          <h2>Join Our Wait-list - Get Benefits</h2>
          <p>Sign up to be among the first to know when our platform goes live or participate in our early access MVP program.</p>
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSd8AITO_NG0FjTOymEi4Pa0kMEOKzO5kw2aa4XjY5jyD54Mtg/viewform?embedded=true" className="cta-button sign-up-button">Sign-Up to Waiting List</a>
        </div>
        <div className="footer-logo">
          <img src={logo} alt="Verdantia Law Logo" className="logo-image" />
        </div>
      </footer>

      {/* Modal for displaying the full-size image */}
      {showOverlay && (
        <div className="image-overlay" onClick={closeOverlay}>
          <div className="image-overlay-content">
            <img src={overlayImage} alt="Full-size" className="full-image" />
            <button className="close-button" onClick={closeOverlay}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
