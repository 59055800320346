import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

// Dynamically add the viewport meta tag to the document head
const meta = document.createElement('meta');
meta.name = "viewport";
meta.content = "width=device-width, initial-scale=1";
document.getElementsByTagName('head')[0].appendChild(meta);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
